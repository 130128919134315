
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  notEmpty,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  createTabErrorFinder,
  FormField,
  FormFieldCheckbox,
  FormFieldOptionsSwitcher,
  FormFieldOptionsSwitcherTrueFalse,
  FormFieldSelect,
  FormFooter,
  OptionType,
  type TabType,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getActiveWorkerListUrl, getWorkerFormUrl } from "@/utils/paths";
import {
  communicationOptionList,
  convertCompanyListToOption,
  convertFormValuesToWorkerCreationPayload,
  convertFormValuesToWorkerUpdatePayload,
  convertWorkerToFormValues,
  FormValues,
  workerRewardOptions,
} from "@/modules/workers/WorkerForm/WorkerForm.helpers";
import { CompanyType, FullWorkerType } from "@/types";
import {
  createWorker,
  getWorker,
  updateWorker,
} from "@/modules/workers/requests";
import { getCompanyList } from "@/requests";
import { getCities } from "@/modules/cities/services";
import { CityInterface } from "@/modules/cities/typings";
import { getOrdersByWorker } from "@/modules/orders/services";
import OrdersListTable from "@/modules/orders/containers/orders-list/OrdersList.table.vue";

export default defineComponent({
  name: "WorkerForm",
  components: {
    OrdersListTable,
    FormFieldCheckbox,
    FormField,
    FormFieldSelect,
    Page,
    FormFooter,
    FormFieldOptionsSwitcherTrueFalse,
    FormFieldOptionsSwitcher,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const workerId = computed<string>(() => route.params.workerId as string);

    const isCreation = computed<boolean>(() => workerId.value === "create");

    const [fetchWorker, { data: worker, loading: isWorkerLoading }] =
      useResource<Nullable<FullWorkerType>>({
        fetchResource: () => getWorker(workerId.value),
        initialValue: null,
        resourceName: "worker",
      });

    const [
      fetchCompanyList,
      { data: companyList, loading: isCompanyListLoading },
    ] = useResource<Array<CompanyType>>({
      fetchResource: getCompanyList,
      initialValue: [],
      resourceName: "Company list",
    });

    const [fetchCityList, { data: cityList, loading: isCityListLoading }] =
      useResource<Array<CityInterface>>({
        fetchResource: getCities,
        initialValue: [],
        resourceName: "Cities list",
      });

    onMounted(() => {
      fetchCompanyList();
      fetchCityList();

      if (isCreation.value) return;
      fetchWorker();
    });

    const companyOptionList = ref<Array<OptionType<Nullable<number>>>>([]);
    watch(companyList, () => {
      companyOptionList.value = convertCompanyListToOption(companyList.value);
    });

    const cityOptionList = ref<Array<OptionType<Nullable<number>>>>([]);
    watch(cityList, () => {
      cityOptionList.value = cityList.value.map((city) => ({
        value: city.id,
        label: city.name,
      }));
    });

    const values = ref<FormValues>(convertWorkerToFormValues(worker.value));

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch(worker, () => {
      values.value = convertWorkerToFormValues(worker.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const creationBody = convertFormValuesToWorkerCreationPayload(
        values.value
      );

      const updateBody = convertFormValuesToWorkerUpdatePayload(values.value);
      const requestPromise = isCreation.value
        ? createWorker(creationBody)
        : updateWorker(workerId.value, updateBody);

      requestPromise
        .then((response) => {
          errors.value = {};
          if (event.type === "create") {
            router.push(getWorkerFormUrl({ workerId: response.data.id }));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getActiveWorkerListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = convertWorkerToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: isCreation.value
              ? `Клинер успешно создан`
              : "Клинер успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: isCreation.value
              ? `Ошибка при создании клинера`
              : "Ошибка при обновлении клинера",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => isWorkerLoading.value || isCompanyListLoading.value
    );

    /** Tabs */

    const tabList = computed<Array<TabType>>(() => {
      const hasErrors = createTabErrorFinder(errors.value);

      return [
        {
          id: "common",
          label: "Основные данные",
          hasErrors: hasErrors([
            "number",
            "name",
            "surname",
            "patronymic",
            "birthday",
            "address",
            "additionalInformation",
          ]),
        },
        {
          id: "contact",
          label: "Контактные данные",
          hasErrors: hasErrors(["email", "phone", "contactMethod"]),
        },
        {
          id: "documents",
          label: "Документы",
          hasErrors: hasErrors(["contractNumber", "contractDate", "passport"]),
        },
        {
          id: "work",
          label: "Условия работы",
          hasErrors: hasErrors([
            "hourRate",
            "percent",
            "hasWindowsTools",
            "hasHoover",
            "workingDaysPerMonth",
          ]),
        },
        isCreation.value
          ? null
          : {
              id: "orders",
              label: "Заказы",
            },
      ].filter(notEmpty);
    });

    const selectedTabId = ref<string>(tabList.value[0].id);
    const PageTitle = isCreation.value
      ? "Создание Клинера"
      : "Редактирование клинера";

    return {
      backButtonUrl: getActiveWorkerListUrl(),
      values,
      errors,
      isSubmitting,
      submitForm,
      isContentLoading,
      worker,
      tabList,
      selectedTabId,
      isCreation,
      PageTitle,
      communicationOptionList,
      companyOptionList,
      cityOptionList,
      workerRewardOptions,

      fetchOrders: () => getOrdersByWorker(+workerId.value)(),
    };
  },
});
