import { Nullable } from "@tager/admin-services";

import {
  AdditionalServiceCategoryCreationPayload,
  AdditionalServiceCategoryUpdatePayload,
} from "@/requests";
import { FullAdditionalServiceCategoryType } from "@/types";

export type FormValues = {
  name: string;
  quantity: string;
  price: string;
  minutes: string;
  fullWorkerPrice: string;
  minCleanerReward: number | null;
  rewardPrimaryOrder: number[];
};

export function convertAdditionalServiceCategoryToFormValues(
  additionalServiceCategory: Nullable<FullAdditionalServiceCategoryType>
): FormValues {
  if (!additionalServiceCategory) {
    return {
      name: "",
      quantity: "0",
      price: "0",
      minutes: "0",
      fullWorkerPrice: "",
      minCleanerReward: null,
      rewardPrimaryOrder: [],
    };
  }

  const rewardPrimaryOrder: number[] = [];
  additionalServiceCategory.rewardPrimaryOrder.forEach(
    (item) => (rewardPrimaryOrder[item.city] = +item.reward)
  );

  return {
    name: additionalServiceCategory.name,
    quantity: String(additionalServiceCategory.quantity),
    price: String(additionalServiceCategory.price),
    minutes: String(additionalServiceCategory.minutes),
    fullWorkerPrice: additionalServiceCategory.fullWorkerPrice
      ? String(additionalServiceCategory.fullWorkerPrice)
      : "",
    minCleanerReward: additionalServiceCategory.minCleanerReward,
    rewardPrimaryOrder,
  };
}

export function convertFormValuesToAdditionalServiceCategoryCreationPayload(
  values: FormValues,
  categoryId: number | string
): AdditionalServiceCategoryCreationPayload {
  return {
    name: values.name,
    quantity: Number(values.quantity),
    price: Number(values.price),
    minutes: Number(values.minutes),
    categoryId: Number(categoryId),
    fullWorkerPrice: Number(values.fullWorkerPrice),
    minCleanerReward: Number(values.minCleanerReward),
    rewardPrimaryOrder: Object.keys(values.rewardPrimaryOrder).map((city) => {
      return {
        city: +city,
        reward:
          city in values.rewardPrimaryOrder
            ? +values.rewardPrimaryOrder[+city]
            : null,
      };
    }),
  };
}

export function convertFormValuesToAdditionalServiceCategoryUpdatePayload(
  values: FormValues,
  categoryId: number | string
): AdditionalServiceCategoryUpdatePayload {
  return {
    ...convertFormValuesToAdditionalServiceCategoryCreationPayload(
      values,
      categoryId
    ),
  };
}
