
import { defineComponent, onMounted, ref } from "vue";

import { DataTable, FieldValue, ToggleSection } from "@tager/admin-ui";

import {
  COLUMN_DEFS,
  rowCssClass,
  useBalanceData,
} from "@/modules/users/users-edit/containers/UsersEditBalance/UsersEditBalance.helpers";
import UsersEditBalanceForm from "@/modules/users/users-edit/containers/UsersEditBalance/containers/UsersEditBalanceForm.vue";

export default defineComponent({
  name: "UsersEditBalance",
  components: {
    UsersEditBalanceForm,
    DataTable,
    ToggleSection,
    FieldValue,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const balanceValue = ref<number>(+props.balance);

    const {
      fetchEntityList: fetchData,
      isLoading: isRowDataLoading,
      rowData: data,
      errorMessage,
    } = useBalanceData(props.id);

    onMounted(fetchData);

    function isBusy(): boolean {
      return isRowDataLoading.value;
    }

    const getRowBalance = (index: number): number => {
      let result = balanceValue.value;
      console.log(data.value);
      for (let i = 0; i < index; i++) {
        result -= data.value[i]?.sum || 0;
      }
      return result;
    };

    const onBalanceSubmit = ({ balance }: { balance: number }) => {
      balanceValue.value = balance;
      fetchData();
    };

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading,
      isBusy,
      errorMessage: errorMessage,
      rowCssClass,
      getRowBalance,
      onBalanceSubmit,
      balanceValue,
    };
  },
});
