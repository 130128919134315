import { UserAddress } from "@/modules/orders/views/orders-create/typings";
import { AddressFormFields } from "@/modules/orders/typings";

export const formatAddressInfo = (address: UserAddress): string => {
  const parts = [];

  if (address.entrance) {
    parts.push("подъезд " + address.entrance);
  }

  if (address.floor) {
    parts.push("этаж " + address.floor);
  }

  if (address.intercomCode) {
    parts.push('код от домофона "' + address.intercomCode + '"');
  }

  return parts.join(", ");
};

export const validateCustomAddress = (address: AddressFormFields): boolean => {
  return !!(
    address.street &&
    address.street.trim().length > 0 &&
    address.house
  );
};

export const formatAddressMain = (address: UserAddress): string => {
  let result = "Ул. " + address.street + ", д. " + address.house;

  if (address.wing) {
    result += "/" + address.wing;
  }

  if (address.apartment) {
    result += ", кв. " + address.apartment;
  }

  return result;
};

export const validatePhoneNumber = (phone: string): boolean => {
  const phoneValue = phone.trim() || "";
  if (phoneValue.length === 0) return false;

  return phoneValue.length >= 8;
};
