
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import { FormField, FormFooter, TagerFormSubmitEvent } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { FullAdditionalServiceCategoryType } from "@/types";
import {
  createAdditionalServiceCategory,
  getAdditionalServiceCategory,
  updateAdditionalServiceCategory,
} from "@/requests";
import {
  convertAdditionalServiceCategoryToFormValues,
  convertFormValuesToAdditionalServiceCategoryCreationPayload,
  convertFormValuesToAdditionalServiceCategoryUpdatePayload,
  FormValues,
} from "@/modules/services/AdditionalServiceCategoryForm/AdditionalServiceCategoryForm.helpers";
import {
  getAdditionalServiceCategoryFormUrl,
  getAdditionalServiceCategoryListUrl,
} from "@/modules/services";
import { useCities } from "@/store/city";

export default defineComponent({
  name: "AdditionalServiceCategoryForm",
  components: { FormField, Page, FormFooter },
  setup() {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const { loading: citiesLoading, items: cities } = useCities();

    const additionalServiceId = computed(
      () => route.params.additionalServiceId as string
    );

    const serviceCategoryId = computed(
      () => route.params.serviceCategoryId as string
    );

    const isCreation = computed<boolean>(
      () => additionalServiceId.value === "create-service"
    );

    const [
      fetchAdditionalServiceCategory,
      {
        data: additionalAdditionalServiceCategory,
        loading: isAdditionalServiceCategoryLoading,
      },
    ] = useResource<Nullable<FullAdditionalServiceCategoryType>>({
      fetchResource: () =>
        getAdditionalServiceCategory(additionalServiceId.value || ""),
      initialValue: null,
      resourceName: "additionalAdditionalServiceCategory",
    });

    onMounted(() => {
      if (isCreation.value) return;
      fetchAdditionalServiceCategory();
    });

    const values = ref<FormValues>(
      convertAdditionalServiceCategoryToFormValues(
        additionalAdditionalServiceCategory.value
      )
    );

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch(additionalAdditionalServiceCategory, () => {
      values.value = convertAdditionalServiceCategoryToFormValues(
        additionalAdditionalServiceCategory.value
      );
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const creationBody =
        convertFormValuesToAdditionalServiceCategoryCreationPayload(
          values.value,
          serviceCategoryId.value || ""
        );

      const updateBody =
        convertFormValuesToAdditionalServiceCategoryUpdatePayload(
          values.value,
          serviceCategoryId.value || ""
        );

      const requestPromise = isCreation.value
        ? createAdditionalServiceCategory(creationBody)
        : updateAdditionalServiceCategory(
            additionalServiceId.value || "",
            updateBody
          );

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(
              getAdditionalServiceCategoryFormUrl({
                serviceCategoryId: serviceCategoryId.value || "",
                additionalServiceId: response.data.id,
              })
            );
          }
          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(
              router,
              getAdditionalServiceCategoryListUrl({
                serviceCategoryId: serviceCategoryId.value || "",
              })
            );
          }
          if (event.type === "create_create-another") {
            values.value = convertAdditionalServiceCategoryToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: isCreation.value
              ? `Услуга успешно добавлена`
              : "Услуга успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: isCreation.value
              ? `Ошибка при добавление услуги`
              : "Ошибка при редактирование услуги",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => isAdditionalServiceCategoryLoading.value
    );

    const pageTitle = computed<string>(() =>
      isCreation.value ? "Добавление услуги" : "Редактирование услуги"
    );

    return {
      backButtonUrl: getAdditionalServiceCategoryListUrl({
        serviceCategoryId: serviceCategoryId.value,
      }),
      values,
      errors,
      isSubmitting,
      submitForm,
      additionalAdditionalServiceCategory,
      isCreation,
      isContentLoading,
      cities,
      pageTitle,
    };
  },
});
