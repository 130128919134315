
import { computed, defineComponent, onMounted, ref, watch } from "vue";

import { BaseButton, FormField } from "@tager/admin-ui";
import { getMessageFromError, useToast } from "@tager/admin-services";

import { findUser } from "@/modules/orders/views/orders-create/services";
import { FindUserResponse } from "@/modules/orders/views/orders-create/typings";
import { validatePhoneNumber } from "@/modules/orders/views/orders-create/helpers";
import { getUsersUpdateUrl } from "@/modules/users";

export default defineComponent({
  name: "OrdersCreateCustomer",
  components: { FormField, BaseButton },

  props: {},
  emits: ["change"],

  setup(props, { emit }) {
    const phoneInputRef = ref<HTMLDivElement>();
    const customerNameInputRef = ref<HTMLDivElement>();

    const customerName = ref<string>("");
    const phone = ref<string>("");
    const loaded = ref<boolean>(false);
    const customer = ref<FindUserResponse | null | false>(false);

    const reset = () => {
      customer.value = false;
      loaded.value = false;
      emit("change", null);
    };

    const toast = useToast();

    onMounted(() => {
      phoneInputRef.value?.querySelector("input")?.focus();
    });

    const userData = ref<FindUserResponse | null>(null);
    const userLoading = ref<boolean>(false);

    const requestUser = () => {
      userLoading.value = true;
      userData.value = null;
      loaded.value = false;

      findUser(phone.value)
        .then((data) => {
          loaded.value = true;
          if (!data.data) {
            customer.value = null;
            customerName.value = "";
            setTimeout(() => {
              customerNameInputRef.value?.querySelector("input")?.focus();
            });

            emit("change", null);
          } else {
            customer.value = data.data;
            emit("change", data.data.id);
          }
        })
        .catch((e) => {
          loaded.value = false;
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: e.body.errors.phone.message || getMessageFromError(e),
          });
        })
        .finally(() => {
          userLoading.value = false;
        });
    };

    watch([customerName], () => {
      if (customer.value === null) {
        if (customerName.value.trim().length) {
          emit("change", {
            phone: phone.value,
            name: customerName.value,
          });
        } else {
          emit("change", null);
        }
      }
    });

    const onSearch = (e: FormDataEvent) => {
      e.preventDefault();
      requestUser();
    };

    const searchDisabled = computed<boolean>(() => {
      if (userLoading.value) return true;

      return !validatePhoneNumber(String(phone.value));
    });

    return {
      phoneInputRef,
      customerNameInputRef,
      phone,
      loaded,
      loading: userLoading,
      searchDisabled,
      customer,
      onSearch,
      reset,
      customerName,
      getUsersUpdateUrl,
    };
  },
});
