import { Nullable, request, ResponseBody } from "@tager/admin-services";

import { FullWorkerType, ReportType, WorkerTypeList } from "@/types";
import { CountData } from "@/requests";
import { WorkerRewardType } from "@/modules/workers/WorkerForm/WorkerForm.helpers";

type SuccessData = { success: boolean };

/** Worker */
export function getWorkerCount(
  city?: number
): Promise<ResponseBody<CountData>> {
  const query = city ? { city } : {};
  return request.get({ path: "/admin/workers/count", params: query });
}

export type WorkerSavePayload = {
  isArchived: boolean;
  archivedDate: string | null;
  archivedReason: string | null;

  rewardType: WorkerRewardType;
  number: string;
  name: string;
  surname: string;
  patronymic: string;
  birthday: string;
  address: string;
  email: string;
  phone: string;
  contactMethod: Nullable<string>;
  company: Nullable<number>;
  city: Nullable<number>;
  contractNumber: string;
  contractDate: string;
  passport: string;
  isHighReward: boolean;
  hourRate: number;
  percent: number;
  hasWindowsTools: boolean;
  hasHoover: boolean;
  workingDaysPerMonth: number;
  additionalInformation: string;
  infoForTimetable: string;
};

export function createWorker(
  payload: WorkerSavePayload
): Promise<ResponseBody<FullWorkerType>> {
  return request.post({ path: "/admin/workers", body: payload });
}

export function updateWorker(
  workerId: string | number,
  payload: WorkerSavePayload
): Promise<ResponseBody<FullWorkerType>> {
  return request.put({
    path: `/admin/workers/${workerId}`,
    body: payload,
  });
}

export function getWorker(
  workerId: string | number
): Promise<ResponseBody<FullWorkerType>> {
  return request.get({ path: `/admin/workers/${workerId}` });
}

export function getAllWorkersList(): Promise<
  ResponseBody<Array<WorkerTypeList>>
> {
  return request.get({
    path: "/admin/workers/all",
    params: {
      pageSize: 10000,
      pageNumber: 1,
    },
  });
}

/** Active Worker */
export function getActiveWorkerList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<WorkerTypeList>>> {
  return request.get({
    path: "/admin/workers",
    params,
  });
}

export function setArchiveWorker(
  workerId: string | number
): Promise<SuccessData> {
  return request.post({ path: `/admin/workers/${workerId}/archive` });
}

/** Archive Worker */
export function getArchiveWorkerList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<
  ResponseBody<Array<WorkerTypeList>, { averageWorkingDays: number }>
> {
  return request.get({ path: "/admin/workers/archive", params });
}

export function setAsNotArchiveWorker(
  workerId: string | number
): Promise<ResponseBody<SuccessData>> {
  return request.post({ path: `/admin/workers/${workerId}/restore` });
}

/** Report */

export type ReportCreationPayload = {
  from: string;
  to: string;
};

export function generateReport(
  workerId: string | number,
  payload: ReportCreationPayload
): Promise<ResponseBody<ReportType>> {
  return request.post({
    path: `/admin/workers/${workerId}/report/generate`,
    body: payload,
  });
}

export function sendReport(
  workerId: number,
  reportId: number
): Promise<ResponseBody<ReportType>> {
  return request.post({
    path: `/admin/workers/${workerId}/report/${reportId}/send`,
  });
}
